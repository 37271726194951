import React from 'react'

export default function AppleIcon() {
  return (
    <span className="icon icon--apple">
      <svg width="21" height="26" viewBox="0 0 21 26">
        <path d="M17.336 13.567c.033 3.863 3.38 5.14 3.42 5.154-.02.09-.524 1.844-1.78 3.624-1.047 1.567-2.145 3.1-3.89 3.127-1.693.04-2.263-.996-4.206-.996-1.96 0-2.568.97-4.193 1.036-1.66.06-2.93-1.673-4.016-3.226-2.17-3.17-3.86-8.936-1.594-12.86 1.098-1.923 3.1-3.162 5.24-3.195C7.98 6.194 9.522 7.35 10.544 7.35c1.002 0 2.91-1.384 4.877-1.177.824.024 3.166.325 4.677 2.528-.122.073-2.79 1.64-2.76 4.864zm-3.2-9.478c-.912 1.075-2.37 1.898-3.792 1.792-.186-1.452.534-2.996 1.34-3.938C12.594.874 14.177.056 15.442 0c.166 1.51-.432 2.996-1.306 4.09z" fillRule="nonzero" fill="#FFF"></path>
      </svg>
    </span>
  )
}
