import React from "react"

import AppStoreLink from '@components/app-store-link'
import AppleIcon from '@components/apple-icon'
import Layout from "@components/layout"
import SEO from "@components/seo"

const FosterSuccessPage = () => (
  <Layout bodyClassName='foster'>
    <SEO title="Foster Welcome" />
    <SEO 
      title="Foster Welcome"
      description="Hey shelters, adoption agencies, and dog fosters. Welcome to Harper!"
    />

    <section className="stripe stripe--hero stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h1 className="pageTitle">Welcome to Harper!</h1>
          <p className="lede">Download the app to get started today</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered">
      <div className="container">
        <div className="copy">
          <h2 className="sectionTitle">You&rsquo;re in</h2>
          <p className="prose">Now you can download the app and create your free account.</p>
          <p className="prose"style={{ marginBottom: "3rem" }}>Make sure to select <strong>Continue with SMS</strong> and use the phone number you just entered. That&rsquo;s how we&rsquo;ll know it&rsquo;s you.</p>
          <AppStoreLink onClick={() => window.fbq('track', 'Download')} className="btn btn--primary">
            <AppleIcon />
            <span>Download</span>
          </AppStoreLink>
        </div>
      </div>
  </section>
  <section className="stripe stripe--alt stripe--centered">
      <div className="container">
        <div className="copy">
          <h2 className="sectionTitle">Anything you need</h2>
          <p className="prose" style={{ marginBottom: "1rem" }}>Training questions, app issues, feature requests? Reach out any time.</p>
          <div className="btnGroup btnGroup--block btnGroup--two">
            <a href="tel:+1-513-818-2190" className="btn">Give us a call</a>
            <a href="sms:+1-513-818-2190" className="btn">Live chat us</a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default FosterSuccessPage
