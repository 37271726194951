import React from "react"
import PropTypes from "prop-types"

import BodyClass from "./body-class"
import Header from "./header"
import Footer from "./footer"
import "./layout.scss"

const Layout = ({
  bodyClassName,
  children,
}) => {
  return (
    <BodyClass
      bodyClassName={bodyClassName}
    >
      <Header title="Harper" showNavigation={true} />

      <main>
        {children}
      </main>

      <Footer />
    </BodyClass>
  )
}

Layout.propTypes = {
  bodyClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
